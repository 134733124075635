import { WellTypes } from '../../../../../shared/enums/well-type';
import { DW_ID, NDW_ID } from '../../../../../shared/utils/tree';
import { FaInfoCircle } from 'react-icons/fa';
import styles from './index.module.css';
import { useContext } from 'react';
import { GeneralContext } from '../../../../../services/context/context';

const GroundwaterSource = ({ gis, selectedItems = [] }) => {
  const isDrinkingWaterActive = selectedItems[DW_ID];
  const isNonDrinkingWaterActive = selectedItems[NDW_ID];
  const { openPopup, closePopup } = useContext(GeneralContext);

  const drinkingSources = Object.entries(gis).map(([village, sourceList]) => ({
    village,
    sources: sourceList.filter(
      (source) => source.utility_type === WellTypes.DRINKING,
    ),
  }));

  const nonDrinkingSources = Object.entries(gis).map(
    ([village, sourceList]) => ({
      village,
      sources: sourceList.filter(
        (source) => source.utility_type === WellTypes.NON_DRINKING,
      ),
    }),
  );

  const handlePopupClick = (reason) => {
    openPopup(
      <div className={styles.popupContentWrapper}>
        <h2 className={styles.popupTitle}>Vulnerability Reason</h2>
        <p className={styles.popupMessage}>{reason}</p>
        <button className={styles.popupButton} onClick={() => closePopup()}>
          Okay
        </button>
      </div>,
    );
  };

  return (
    <>
      {isDrinkingWaterActive && (
        <div>
          <div className={styles.header}>Drinking water Sources</div>
          <div className={styles.container}>
            {drinkingSources.map((ds) => {
              return (
                <div className={styles.tableContainer} key={ds.village}>
                  <div className={styles.tableHeader}>
                    <div style={{ marginLeft: '8px' }}>{ds.village}</div>
                  </div>
                  <div className={styles.table}>
                    <div className={styles.tableRow}>
                      <div
                        className={styles.tableCellHeader}
                        style={{
                          borderRight: '0.5px solid #fff',
                          borderRadius: '0px',
                        }}
                      >
                        Name of source
                      </div>
                      <div
                        className={styles.tableCellHeader}
                        style={{ borderRight: '0.5px solid #fff' }}
                      >
                        Source vulnerability status
                      </div>
                      <div className={styles.tableCellHeader}>
                        Quality vulnerability status
                      </div>
                    </div>
                    {ds.sources.map((source, index) => (
                      <div
                        className={styles.tableRow}
                        key={index}
                        style={{
                          borderBottom:
                            index === ds.sources.length - 1
                              ? 'none'
                              : 'undefined',
                        }}
                      >
                        <div
                          className={styles.tableCell}
                          style={{
                            borderRight: '0.5px solid #fff',
                            borderBottomLeftRadius:
                              index === ds.sources.length - 1
                                ? '15px'
                                : 'undefined',
                          }}
                        >
                          {source.name}
                        </div>
                        <div
                          className={styles.tableCell}
                          style={{ borderRight: '0.5px solid #fff' }}
                        >
                          <div style={{ fontWeight: 700, fontSize: '12px' }}>
                            {source.is_vulnerable ? 'YES' : 'NO'}&nbsp;
                            <FaInfoCircle
                              className={styles.info}
                              onClick={() =>
                                handlePopupClick(source.vulnerability_reason)
                              }
                            />
                          </div>
                        </div>
                        <div className={styles.tableCell}>
                          <div
                            style={{
                              fontWeight: 700,
                              fontSize: '12px',
                              borderBottomRightRadius:
                                index === ds.sources.length - 1
                                  ? '15px'
                                  : 'undefined',
                            }}
                          >
                            {source.is_quality_vulnerable ? 'YES' : 'NO'}&nbsp;
                            <FaInfoCircle
                              className={styles.info}
                              onClick={() =>
                                handlePopupClick(
                                  source.quality_vulnerability_reason,
                                )
                              }
                            />
                          </div>
                          {/* {source.qualityVulnerability === true && (
                            <div className={styles.reason}>
                              {source.quality_vulnerability_reason}
                            </div>
                          )} */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {isNonDrinkingWaterActive && (
        <div>
          <div className={styles.header}>Non-Drinking water Sources</div>
          <div className={styles.container}>
            {nonDrinkingSources.map((nds) => {
              return (
                <div key={nds.village} className={styles.tableContainer}>
                  <div className={styles.tableHeader}>
                    <div style={{ marginLeft: '8px' }}>{nds.village}</div>
                  </div>
                  <div className={styles.table}>
                    <div className={styles.tableRow}>
                      <div
                        className={styles.tableCellHeader}
                        style={{
                          borderRight: '0.5px solid #fff',
                          borderRadius: '0px',
                        }}
                      >
                        Name of source
                      </div>
                      <div
                        className={styles.tableCellHeader}
                        style={{ borderRight: '0.5px solid #fff' }}
                      >
                        Source vulnerability status
                      </div>
                      <div className={styles.tableCellHeader}>
                        Quality vulnerability status
                      </div>
                    </div>
                    {nds.sources.map((source, index) => (
                      <div
                        className={styles.tableRow}
                        key={index}
                        style={{
                          borderBottom:
                            index === nds.sources.length - 1
                              ? 'none'
                              : 'undefined',
                        }}
                      >
                        <div
                          className={styles.tableCell}
                          style={{
                            borderRight: '0.5px solid #fff',
                            borderBottomLeftRadius:
                              index === nds.sources.length - 1
                                ? '15px'
                                : 'undefined',
                          }}
                        >
                          {source.name}
                        </div>
                        <div
                          className={styles.tableCell}
                          style={{ borderRight: '0.5px solid #fff' }}
                        >
                          <div style={{ fontWeight: 700, fontSize: '12px' }}>
                            {source.is_vulnerable ? 'YES' : 'NO'}&nbsp;
                            <FaInfoCircle
                              className={styles.info}
                              onClick={() =>
                                handlePopupClick(source.vulnerability_reason)
                              }
                            />
                          </div>
                          {/* {source.is_vulnerable === true && (
                            <div className={styles.reason}>
                              {source.vulnerability_reason}
                            </div>
                          )} */}
                        </div>
                        <div className={styles.tableCell}>
                          <div
                            style={{
                              fontWeight: 700,
                              fontSize: '12px',
                              borderBottomRightRadius:
                                index === nds.sources.length - 1
                                  ? '15px'
                                  : 'undefined',
                            }}
                          >
                            {source.is_quality_vulnerable ? 'YES' : 'NO'}&nbsp;
                            <FaInfoCircle
                              className={styles.info}
                              onClick={() =>
                                handlePopupClick(
                                  source.quality_vulnerability_reason,
                                )
                              }
                            />
                          </div>
                          {/* {source.qualityVulnerability === true && (
                            <div className={styles.reason}>
                              {source.quality_vulnerability_reason}
                            </div>
                          )} */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default GroundwaterSource;
